import React from "react";
import { Fab } from "@mui/material";
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { useNavigate } from "react-router-dom";

const FloatingBackButton = () => {
    const navigate = useNavigate();

    return (
        <Fab
            style={{background: "white"}}
            aria-label="home"
            onClick={() => navigate("/")}
            sx={{
                position: "fixed",
                top: 32,
                left: 32,
                zIndex: 1000, // Ensures it stays on top
            }}
        >
            <ArrowBackIosNewRoundedIcon />
        </Fab>
    );
};

export default FloatingBackButton;