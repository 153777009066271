import startContent from "./start-content.json";
import FloatingBackButton from "../components/FloatingBackButton";
import ParallaxPage from "../components/ParallaxPage";
import React from "react";
import {Typography} from "@mui/material";
import appsContent from "./apps-content.json";
import {Spacer} from "../components/Spacer";

export const Apps = () => {
    // const paragraphComponent = (paragraph: Paragraph) => {
    //     return (
    //         <div>
    //             <h3>{paragraph.title}</h3>
    //             {paragraph.textContent.map((sentence, index) => (
    //                 <Typography
    //                     variant="body1"
    //                     paragraph
    //                     key={index}
    //                     sx={{
    //                         wordBreak: "break-word",
    //                         overflowWrap: "break-word",
    //                         whiteSpace: "normal"
    //                     }}
    //                 >
    //                     {sentence}
    //                 </Typography>
    //             ))}
    //             {paragraph.links.map((link, index) => (
    //                 <Link component={RouterLink} to={link.linkUrl} variant="body1">
    //                     {link.linkText}
    //                 </Link>
    //             ))}
    //         </div>
    //     )
    // }

    const pageContent = (
        <div>
            <h1>{appsContent.title}</h1>
            <h2>{appsContent.subtitle}</h2>

            <Spacer height={100}/>

            <Typography
                variant="body1"
                paragraph
                sx={{
                    wordBreak: "break-word",
                    overflowWrap: "break-word",
                    whiteSpace: "normal"
                }}
            >
                Content coming soon...
            </Typography>

            {/*{appsContent.paragraphs.map((paragraph, index) => (*/}
            {/*    <div key={index}>*/}
            {/*        {index % 2 === 0*/}
            {/*            ? <ImageLeftContentRight*/}
            {/*                imageUrl={paragraph.imageUrl}*/}
            {/*                content={paragraphComponent(paragraph)}*/}
            {/*            />*/}
            {/*            : <ImageRightContentLeft*/}
            {/*                imageUrl={paragraph.imageUrl}*/}
            {/*                content={paragraphComponent(paragraph)}*/}
            {/*            />*/}
            {/*        }*/}
            {/*        <div>*/}
            {/*            {index !== appsContent.paragraphs.length - 1*/}
            {/*                ? <><Spacer height={150}/><Divider/><Spacer height={150}/></>*/}
            {/*                : <><Spacer height={150}/></>*/}
            {/*            }*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*))}*/}
        </div>
    )

    return (
        <div>
            <FloatingBackButton/>
            <ParallaxPage
                imageUrl={startContent.apps.imageUrl}
                content={pageContent}
            />
        </div>
    );
};