import React from 'react';
import './App.css';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {AppRoute} from "./app-route";
import {Home} from "./pages/Home";
import {Apps} from "./pages/Apps";
import {Web} from "./pages/Web";
import {Desktop} from "./pages/Desktop";
import {Visions} from "./pages/Visions";
import {About} from "./pages/About";
import {NotFound} from "./pages/NotFound";
import {Start} from "./pages/Start";
import {DynamicBackground} from "./components/DynamicBackground";

const App = () => {

    const routes = () => {
        return <Routes>
            <Route path={AppRoute.START} element={<Start/>}/>
            <Route path={AppRoute.HOME} element={<Home/>}/>
            <Route path={AppRoute.APPS} element={<Apps/>}/>
            <Route path={AppRoute.WEB} element={<Web/>}/>
            <Route path={AppRoute.DESKTOP} element={<Desktop/>}/>
            <Route path={AppRoute.VISIONS} element={<Visions/>}/>
            <Route path={AppRoute.ABOUT} element={<About/>}/>
            <Route path={AppRoute.NOT_FOUND} element={<NotFound/>}/>
        </Routes>;
    }

    return (
        <Router>
            <DynamicBackground>
                <nav>{/* Navigation bar if needed (optional links, etc.) */}</nav>
                {routes()}
            </DynamicBackground>
        </Router>
    );
}

export default App;