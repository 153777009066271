import {Box} from "@mui/material";
import React from "react";

interface ImageRightContentLeftParams {
    imageUrl: string,
    content: React.ReactNode
}

const ImageRightContentLeft = ({ imageUrl, content }: ImageRightContentLeftParams) => {
    return (
        <Box display="flex" alignItems="flex-start" gap={4}>
            {/* Left Side: Content */}
            {content}


            {/* Right Side: Image */}
            <Box sx={{alignSelf: "center"}}>
                <Box
                    component="img"
                    src={imageUrl}
                    alt="Image"
                    sx={{
                        width: "450px", // Ensures responsiveness
                        height: "auto",
                        borderRadius: 5,
                    }}
                />
            </Box>
        </Box>
    );
};

export default ImageRightContentLeft;