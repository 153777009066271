import {Box} from "@mui/material";
import React from "react";

interface ImageLeftContentRightParams {
    imageUrl: string,
    content: React.ReactNode
}

const ImageLeftContentRight = ({ imageUrl, content }: ImageLeftContentRightParams) => {
    return (
        <Box display="flex" alignItems="flex-start" gap={4}>
            {/* Left Side: Image */}
            <Box
                component="img"
                src={imageUrl}
                alt="Image"
                sx={{
                    width: "450px", // Ensures responsiveness
                    height: "auto",
                    borderRadius: 5,
                    alignSelf: "center"
                }}
            />

            {/* Right Side: Content */}
            <Box>{content}</Box>
        </Box>
    );
};

export default ImageLeftContentRight;