import FloatingBackButton from "../components/FloatingBackButton";
import ParallaxPage from "../components/ParallaxPage";
import visionsContent from "./visions-content.json";
import {Spacer} from "../components/Spacer";
import {Typography} from "@mui/material";
import startContent from "./start-content.json";
import React from "react";

export const Visions = () => {
    const pageContent = (
        <div>
            <h1>{visionsContent.title}</h1>
            <h2>{visionsContent.subtitle}</h2>

            <Spacer height={100}/>

            <Typography
                variant="body1"
                paragraph
                sx={{
                    wordBreak: "break-word",
                    overflowWrap: "break-word",
                    whiteSpace: "normal"
                }}
            >
                Content coming soon...
            </Typography>
        </div>
    )

    return (
        <div>
            <FloatingBackButton/>
            <ParallaxPage
                imageUrl={startContent.visions.imageUrl}
                content={pageContent}
            />
        </div>
    );
};