import React from 'react';
import {Card, CardContent, CardMedia, Grid, Link} from '@mui/material';
import {Link as RouterLink} from "react-router-dom";
import {AppRoute} from "../app-route";

interface FancyCardProps {
    title: string
    subtitle: string
    imageUrl: string
    appRoute: AppRoute
}

const FancyCard: React.FC<FancyCardProps> = ({title, subtitle, imageUrl, appRoute}) => {
    return (
        // Grid item: Column widths are integer values between 1 and 12
        <Grid item xs={12} sm={6} md={4}>
            <Link component={RouterLink} to={appRoute} variant="body1" sx={{mr: 2}} underline="none">
                <Card
                    style={{borderRadius: '1rem', backgroundColor: "#F5F5F5",}}
                    sx={{
                        '&:hover': {
                            boxShadow: '0 4px 10px rgba(255, 255, 255, 0.5)', // White shadow on hover
                        },
                    }}
                >
                    <CardContent sx={{padding: '8px 16px 8px 16px'}}>
                        <h2>{title}</h2>
                        <p>{subtitle}</p>
                    </CardContent>
                    <CardMedia
                        component="img"
                        height="194"
                        width="100%"
                        image={imageUrl}
                        alt={title}
                    />
                </Card>
            </Link>
        </Grid>
    );
}

export default FancyCard;