import React from "react";
import {useLocation} from "react-router-dom";
import {AppRoute} from "../app-route";

export const DynamicBackground = ({children}: { children: React.ReactNode }) => {
    const location = useLocation();
    const background = location.pathname === AppRoute.START
        ? "linear-gradient(to bottom, #282c34, grey)"
        : "#F5F5F5"
    return (
        <div
            style={{
                minHeight: "100vh",
                background: background,
                transition: "background 0.3s ease",
            }}
        >
            {children}
        </div>
    );
};