import homeContent from './home-content.json';
import startContent from './start-content.json';
import FloatingBackButton from "../components/FloatingBackButton";
import ParallaxPage from "../components/ParallaxPage";
import {Divider, Link, Typography} from "@mui/material";
import React from "react";
import {Link as RouterLink} from "react-router-dom";
import ImageLeftContentRight from "../components/ImageLeftContentRight";
import {Spacer} from "../components/Spacer";
import ImageRightContentLeft from "../components/ImageRightContentLeft";
import {Paragraph} from '../models/paragraph';

export const Home = () => {
    const paragraphComponent = (paragraph: Paragraph) => {
        return (
            <div>
                <h3>{paragraph.title}</h3>
                {paragraph.textContent.map((sentence, index) => (
                    <Typography
                        variant="body1"
                        paragraph
                        key={index}
                        sx={{
                            wordBreak: "break-word",
                            overflowWrap: "break-word",
                            whiteSpace: "normal"
                        }}
                    >
                        {sentence}
                    </Typography>
                ))}
                {paragraph.links.map((link, index) => (
                    <Link component={RouterLink} to={link.linkUrl} variant="body1" key={index}>
                        {link.linkText}
                    </Link>
                ))}
            </div>
        )
    }

    const pageContent = (
        <div>
            <h1>{homeContent.title}</h1>
            <h2>{homeContent.subtitle}</h2>

            <Spacer height={100}/>

            {homeContent.paragraphs.map((paragraph, index) => (
                <div key={index}>
                    {index % 2 === 0
                        ? <ImageLeftContentRight
                            imageUrl={paragraph.imageUrl}
                            content={paragraphComponent(paragraph)}
                        />
                        : <ImageRightContentLeft
                            imageUrl={paragraph.imageUrl}
                            content={paragraphComponent(paragraph)}
                        />
                    }
                    <div>
                        {index !== homeContent.paragraphs.length - 1
                            ? <><Spacer height={150}/><Divider/><Spacer height={150}/></>
                            : <><Spacer height={150}/></>
                        }
                    </div>
                </div>
            ))}
        </div>
    )

    return (
        <div>
            <FloatingBackButton/>
            <div>
                <FloatingBackButton/>
                <ParallaxPage
                    imageUrl={startContent.home.imageUrl}
                    content={pageContent}
                />
            </div>
        </div>
    );
};