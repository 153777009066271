import {Box, Grid} from "@mui/material";
import {AppRoute} from "../app-route";
import FancyCard from "../components/FancyCard";
import {Container} from "@mui/system";
import React from "react";
import content from './start-content.json';

export const Start = () => {
    return (
        <Container>
            <h1 style={{
                fontSize: "5.5rem",
                color: "#F5F5F5",
                textAlign: "center",
                paddingTop: "5rem",
                paddingBottom: "5rem"
            }}>
                livionaef
            </h1>

            {/* TODO: Make Grid dynamic with n items */}
            <Grid container spacing={5}>
                <FancyCard
                    title={content.home.title}
                    subtitle={content.home.subtitle}
                    imageUrl={content.home.imageUrl}
                    appRoute={AppRoute.HOME}
                />

                <FancyCard
                    title={content.apps.title}
                    subtitle={content.apps.subtitle}
                    imageUrl={content.apps.imageUrl}
                    appRoute={AppRoute.APPS}
                />

                <FancyCard
                    title={content.web.title}
                    subtitle={content.web.subtitle}
                    imageUrl={content.web.imageUrl}
                    appRoute={AppRoute.WEB}
                />

                <FancyCard
                    title={content.desktop.title}
                    subtitle={content.desktop.subtitle}
                    imageUrl={content.desktop.imageUrl}
                    appRoute={AppRoute.DESKTOP}
                />

                <FancyCard
                    title={content.visions.title}
                    subtitle={content.visions.subtitle}
                    imageUrl={content.visions.imageUrl}
                    appRoute={AppRoute.VISIONS}
                />

                <FancyCard
                    title={content.about.title}
                    subtitle={content.about.subtitle}
                    imageUrl={content.about.imageUrl}
                    appRoute={AppRoute.ABOUT}
                />
            </Grid>

            <Box height={50}/>

        </Container>
    );
}