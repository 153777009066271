import ParallaxPage from "../components/ParallaxPage";
import content from './not-found-content.json';
import FloatingBackButton from "../components/FloatingBackButton";

export const NotFound = () => {
    const pageContent = <h1>{content.title}</h1>

    return (
        <div>
            <FloatingBackButton/>
            <ParallaxPage
                imageUrl={content.imageUrl}
                content={pageContent}
            />
        </div>
    );
};