import React from "react";
import {Box, Container} from "@mui/material";

interface ParallaxPageParams {
    imageUrl: string,
    content: React.ReactNode
}

const ParallaxPage = ({ imageUrl, content }: ParallaxPageParams) => {
    return (
        <div>
            <Box
                sx={{
                    height: "60vh",
                    backgroundImage: `url(${imageUrl})`,
                    backgroundColor: "white",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundAttachment: "fixed",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                    "&::after": {
                        content: '""',
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundImage: `linear-gradient(
                          to top,
                          rgba(245, 245, 245, 1),
                          rgba(245, 245, 245, 0)
                        )`,
                    },
                }}
            >
            </Box>

            <Container sx={{ py: 5, overflow: "hidden", justifySelf: "center", width: "100%" }}>
                {content}
            </Container>
        </div>
    );
};

export default ParallaxPage;